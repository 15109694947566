import "./PreviewMonitoring.scss";
import React, { Component } from "react";
import { Modal, Col, Row, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faSun, faUmbrella, faSnowman } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import getDataCard from "../Common/Common";
import { getErrorMessage } from "../Common/Common";
import TransectMonitoringResultData from "../Monitoring/TransectMonitoringResultData";
export default class PreviewMonitoring extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeKey: this.props.data.transects[0].name,
        };

        this.setActiveKey = this.setActiveKey.bind(this);
    }


    weatherIcon(weather) {
        switch (weather) {
            case "Sunny":
                return (<FontAwesomeIcon icon={faSun} />);
            case "Cloud":
                return (<FontAwesomeIcon icon={faCloud} />);
            case "Rain":
                return (<FontAwesomeIcon icon={faUmbrella} />);
            case "Snow":
                return (<FontAwesomeIcon icon={faSnowman} />);
            default:
                console.assert(false);
        }
    }

    getValue(value, value_cb) {
        if (value) return value_cb();
        return (<span className="text-danger font-weight-bold">{getErrorMessage("required")}</span>);
    }

    getAlterValue(value, alter, value_cb) {
        if (value) return value_cb();
        return (<span className="text-danger font-weight-bold">{alter}</span>);
    }

    weatherSVG(weather) {
        switch (weather) {
            case "Sunny":
                return process.env.PUBLIC_URL + "/sun-1.svg";
            case "Cloud":
                return process.env.PUBLIC_URL + "/group-29.svg";
            case "Rain":
                return process.env.PUBLIC_URL + "/group-6.svg";
            case "Snow":
                return process.env.PUBLIC_URL + "/group-5.svg";
            default:
                return "";
            // console.assert(false);
        }
    }

    setActiveKey(k) {
        this.setState({ activeKey: k });
    }

    getTabTitle(transects, transectName) {
        return <React.Fragment>{transectName[0].toUpperCase() + transectName.slice(1)}</React.Fragment>;
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size="lg" animation={false} aria-labelledby="contained-modal-title-vcenter" centered className="mx-0 px-0 preview-basic-info">
                <Modal.Header className="PreviewMonitoring-Header-Rect">
                    <div className="modal-title SpoqaHansSans-b-16 c-000">Preview</div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onHide}>
                        <img src={`${process.env.PUBLIC_URL}/icon-24-24-px-close-black-line.svg`} alt=" "/>
                    </button>
                </Modal.Header>
                <Modal.Body className="PreviewMonitoring-Modal-Body-Rect">
                    <Row>
                        <Col
                            className="SpoqaHansSans-b-22 c-303030">
                            Information
                        </Col>
                    </Row>
                    <hr className="mb-0 hr-mt-12px c-e4e9f2"
                    />
                    <Row>
                        <Col className="PreviewMonitoring-Body-BasicInfo NotoSansCJKkr-b-16 c-000"
                        >
                            Basic Information
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" md="4" className="PreviewMonitoring-Body-BasicInfo-Item-Col">
                            <Row>
                                <Col sm="12" className="SpoqaHanSans-b-12 c-8f9bb3">Date</Col>
                                <Col sm="12" className="SpoqaHanSans-r-16 c-000">{this.getValue(this.props.data.date, () => this.props.data.date)}</Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="4" className="PreviewMonitoring-Body-BasicInfo-Item-Col">
                            <Row>
                                <Col sm="12" className="SpoqaHanSans-b-12 c-8f9bb3">Orgranization</Col>
                                <Col sm="12" className="SpoqaHanSans-r-16 c-000">{this.getValue(this.props.data.organization, () => this.props.data.organization)}</Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="4" className="PreviewMonitoring-Body-BasicInfo-Item-Col">
                            <Row>
                                <Col sm="12" className="SpoqaHanSans-b-12 c-8f9bb3">Number of participant</Col>
                                <Col sm="12" className="SpoqaHanSans-r-16 c-000">{this.getValue(String(this.props.data.numberOfParticipant), () => this.props.data.numberOfParticipant)}</Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="4" className="PreviewMonitoring-Body-BasicInfo-Item-Col">
                            <Row>
                                <Col sm="12" className="SpoqaHanSans-b-12 c-8f9bb3">Site</Col>
                                <Col sm="12" className="SpoqaHanSans-r-16 c-000">{this.getValue(this.props.data.site, () => this.props.data.site)}</Col>
                            </Row>
                        </Col>

                        <Col sm="12" md="4" className="PreviewMonitoring-Body-BasicInfo-Item-Col">
                            <Row>
                                <Col sm="12" className="SpoqaHanSans-b-12 c-8f9bb3">(GPS) Latitude</Col>
                                <Col sm="12" className="SpoqaHanSans-r-16 c-000">{this.getValue(this.props.data.latitude, () => this.props.data.latitude)}</Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="4" className="PreviewMonitoring-Body-BasicInfo-Item-Col">
                            <Row>
                                <Col sm="12" className="SpoqaHanSans-b-12 c-8f9bb3">(GPS) Longitude</Col>
                                <Col sm="12" className="SpoqaHanSans-r-16 c-000">{this.getValue(this.props.data.longitude, () => this.props.data.longitude)}</Col>
                            </Row>
                        </Col>

                        <Col sm="12" md="12">
                            <Row>
                                <Col sm="12" className="SpoqaHanSans-b-12 c-8f9bb3">Others</Col>
                                <Col sm="12" className="SpoqaHanSans-r-16 c-000">{this.getValue(this.props.data.others, () => this.props.data.others)}</Col>
                            </Row>
                        </Col>
                    </Row>



                    <Row className="mx-0">
                        <Col className="px-0 PreviewMonitoring-Body-Weather-Rect NotoSansCJKkr-b-16 c-000">Weather</Col>
                    </Row>
                    <Row className="mx-0 PreviewMonitoring-Body-Weather-Row">
                        <Col
                            xs="6"
                            md="3"
                            className="mx-0 px-0"
                            style={{
                                paddingBottom: "10px",
                            }}
                        >
                            <div className="Temperature-Shadow text-center">
                                <div className="Temperature-value">{this.getAlterValue(this.props.data.temperature, " ? ", () => this.props.data.temperature)}ºC</div>
                                {!this.props.data.temperature ? <span className="text-danger font-weight-bold">{getErrorMessage("required")}</span> : undefined}
                                <div className="Temperature-title">Temperature</div>
                            </div>
                        </Col>
                        <Col
                            xs="6"
                            md="3"
                            className="mx-0 px-0"
                            style={{
                                paddingBottom: "10px",
                            }}
                        >
                            <div className="WindDirection-Shadow text-center">
                                <div className="WindDirection-value">{this.getAlterValue(this.props.data.windDirection, " ? ", () => this.props.data.windDirection)}</div>
                                {!this.props.data.windDirection ? <span className="text-danger font-weight-bold">{getErrorMessage("required")}</span> : undefined}
                                <div className="WindDirection-title">Wind direction</div>
                            </div>
                        </Col>
                        <Col
                            xs="6"
                            md="3"
                            className="mx-0 px-0"
                            style={{
                                paddingBottom: "10px",
                            }}
                        >
                            <div className="WindSpeed-Shadow text-center">
                                <div className="WindSpeed-value">{this.getAlterValue(this.props.data.windSpeed, " ? ", () => this.props.data.windSpeed)}</div>
                                {!this.props.data.windSpeed ? <span className="text-danger font-weight-bold">{getErrorMessage("required")}</span> : undefined}
                                <div className="WindSpeed-title">Wind speed</div>
                            </div>
                        </Col>
                        <Col
                            xs="6"
                            md="3"
                            className="mx-0 px-0"
                            style={{
                                paddingBottom: "10px",
                            }}
                        >
                            <div className="Weather-Shadow text-center">
                                <div className="Weather-value">
                                    {this.getAlterValue(this.props.data.weather, " ? ", () => <img src={this.weatherSVG(this.props.data.weather)} alt=" " className="sun-1" />)}
                                </div>
                                {!this.props.data.weather ? <span className="text-danger font-weight-bold">{getErrorMessage("required")}</span> : undefined}
                                <div className="Weather-Card-Title">Weather</div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mx-0">
                        <Col className="px-0 PreviewMonitoring-Body-Weather-Rect NotoSansCJKkr-b-16 c-000">Detail Information</Col>
                    </Row>
                    <Row className="mx-0 PreviewMonitoring-Body-DetailInformation-Row">
                        <Col
                            xs="6"
                            md="3"
                            className="mx-0 px-0"
                            style={{
                                paddingBottom: "10px",
                            }}
                        >
                            <div className="StartTime-Shadow text-center">
                                <div className="StartTime-value">{this.getAlterValue(this.props.data.startTime, " ? ", () => this.props.data.startTime)}</div>
                                {!this.props.data.startTime ? <span className="text-danger font-weight-bold">{getErrorMessage("required")}</span> : undefined}
                                <div className="StartTime-title">Start time</div>
                            </div>
                        </Col>
                        <Col
                            xs="6"
                            md="3"
                            className="mx-0 px-0"
                            style={{
                                paddingBottom: "10px",
                            }}
                        >
                            <div className="EndTime-Shadow text-center">
                                <div className="EndTime-value">{this.getAlterValue(this.props.data.endTime, " ? ", () => this.props.data.endTime)}</div>
                                {!this.props.data.endTime ? <span className="text-danger font-weight-bold">{getErrorMessage("required")}</span> : undefined}
                                <div className="EndTime-title">End time</div>
                            </div>
                        </Col>
                        <Col
                            xs="6"
                            md="3"
                            className="mx-0 px-0"
                            style={{
                                paddingBottom: "10px",
                            }}
                        >
                            <div className="NameOfTeamLeader-Shadow text-center">
                                <div className="NameOfTeamLeader-value">{this.getAlterValue(this.props.data.manager, " ? ", () => this.props.data.manager)}</div>
                                {!this.props.data.manager ? <span className="text-danger font-weight-bold">{getErrorMessage("required")}</span> : undefined}
                                <div className="NameOfTeamLeader-title">Name of team leader</div>
                            </div>
                        </Col>
                        <Col
                            xs="6"
                            md="3"
                            className="mx-0 px-0"
                            style={{
                                paddingBottom: "10px",
                            }}
                        >
                            <div className="LatestTimeOfHighTide-Shadow text-center">
                                <div className="LatestTimeOfHighTide-value">{this.getAlterValue(this.props.data.latestTimeOfHighTide, " ? ", () => this.props.data.latestTimeOfHighTide)}</div>
                                {!this.props.data.latestTimeOfHighTide ? <span className="text-danger font-weight-bold">{getErrorMessage("required")}</span> : undefined}
                                <div className="LatestTimeOfHighTide-title">Latest time of high tide</div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mx-0">
                        <Col className="px-0 PreviewMonitoring-Result-Title SpoqaHansSans-b-22">Result</Col>
                    </Row>

                    <Row className="mt-2 PreviewMonitoringTabs">
                        <Col sm="12">
                            <Row className="mt-2">
                                <Col sm="12">
                                    <Tabs
                                        id="result-tabs"
                                        variant="tabs"
                                        className="mt-0 pt-0 mb-2"
                                        activeKey={this.state.activeKey}
                                        onSelect={(k) => this.setActiveKey(k)}
                                        ref={this.props.forwardedRef}
                                    >
                                        {this.props.data.transects.map((transect, ti) => (
                                            <Tab eventKey={transect.name} key={transect.name} title={this.getTabTitle(this.props.data.transects, transect.name)}>
                                                <Row
                                                    className="mx-0 PreviewMonitoring-Result-Tab-Information"
                                                >
                                                    <Col md="6" sm="12">
                                                        <Row
                                                            style={{
                                                                paddingTop: "16px",
                                                            }}
                                                        >
                                                            <Col
                                                                className="PreviewMonitoring-Result-Info-Column"
                                                            >
                                                                <div className="MonitoringResult-Transect-number SpoqaHanSans-b-12 c-8f9bb3">Transect number</div>
                                                                <div className="MonitoringResult-Transect-number-value SpoqaHanSans-r-16 c-000">{this.getValue(transect.number, () => transect.number)}</div>
                                                            </Col>
                                                            <Col
                                                                className="PreviewMonitoring-Result-Info-Column"
                                                            >
                                                                <div className="MonitoringResult-Transect-length SpoqaHanSans-b-12 c-8f9bb3">Transect legnth</div>
                                                                <div className="MonitoringResult-Transect-length-value SpoqaHanSans-r-16 c-000">
                                                                    {this.getValue(`${transect.lengthFrom}`, () => `${transect.lengthFrom}`)}~
                                                                    {this.getValue(`${transect.lengthTo}`, () => `${transect.lengthTo}`)}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="6" sm="12">
                                                        <Row
                                                            style={{
                                                                paddingTop: "15px",
                                                            }}
                                                        >
                                                            <Col
                                                                className="PreviewMonitoring-Result-Info-Column"
                                                            >
                                                                <div className="MonitoringResult-Width SpoqaHanSans-b-12 c-8f9bb3">Width(m)</div>
                                                                <div className="MonitoringResult-Width-value SpoqaHanSans-r-16 c-000">{this.getValue(transect.width, () => transect.width)}</div>
                                                            </Col>
                                                            <Col
                                                                className="PreviewMonitoring-Result-Info-Column"
                                                            >
                                                                <div className="MonitoringResult-Area SpoqaHanSans-b-12 c-8f9bb3">Area(㎡)</div>
                                                                <div className="MonitoringResult-Area-value SpoqaHanSans-r-16 c-000">{this.getValue(transect.area, () => transect.area)}</div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                {getDataCard().materials.map((material, mi) =>
                                                    material.types.map((type, ti) =>
                                                        type.items.map((item, ii) => (
                                                            <TransectMonitoringResultData
                                                                key={material.name + type.name + item.id}
                                                                materialIndex={mi}
                                                                typeIndex={ti}
                                                                itemIndex={ii}
                                                                typeCount={type.items.length}
                                                                materialDesc={material}
                                                                typeDesc={type}
                                                                itemDesc={item}
                                                                transect={transect}

                                                                classMaterialRow={"MonitoringResult-Material-Text-Row"}
                                                                classMaterial={"NotoSansCJKkr-b-18 c-000"}
                                                                classMaterialWeightRect={"mx-0 MonitoringResult-Material-Weight-Rect"}
                                                                classMaterialWeightName={"ml-0 pl-0 SpoqaHanSans-b-12 c-8f9bb3"}
                                                                classMaterialWeightValue={"mr-0 pr-0 text-right SpoqaHanSans-r-16 c-000"}
                                                                classType={"MonitoringResult-Type-Text-Col NotoSansCJKkr-r-16 c-000"}
                                                                classItemHeaderRow={"mx-0 MonitoringResult-Item-Header-Row"}
                                                                classItemNameColumn={"ml-0 pl-0 SpoqaHanSans-b-12 c-718096"}
                                                                classItemValueColumn={"mr-0 pr-0 MonitoringResult-Item-Value-Col SpoqaHanSans-b-12 c-718096"}
                                                                classItemRow={"mx-0 MonitoringResult-Item-Row"}
                                                                classItemName={"ml-0 pl-0 SpoqaHanSans-r-14 c-454545"}
                                                                classItemValue={"mr-0 pr-0 MonitoringResult-Item-Value-Col SpoqaHanSans-r-14 c-454545"}
                                                                classMaterialHr={"MonitoringResult-Material-Hr"}
                                                            />
                                                        ))
                                                    )
                                                )}
                                            </Tab>
                                        ))}
                                    </Tabs>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer className="py-0 border-0 d-flex justify-content-center">
                    <button
                        className="border-0 text-center PreviewMonitoring-Close-Btn SpoqaHansSans-b-16 c-fff"
                        variant="secondary" onClick={this.props.onHide}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

PreviewMonitoring.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.any,
    data: PropTypes.any,
};
