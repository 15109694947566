import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

//import logo_image from "../images/logo.svg";
import toggle_image_black from "../images/27-icon-menu_black.png";
import toggle_image_white from "../images/27-icon-menu_white.png";

import image_close_button from '../images/icon-24-24-px-close-black-line.png';
import osean_logo1 from '../images/group-8-1.png';  // white
import osean_logo2 from '../images/group-8-2.png';  // color

import { AppContext } from '../AppContext';
import {removeCookie} from "../cookie";

import "./Navigation.css";

class Navigation extends React.Component {
    static contextType = AppContext;

    componentDidMount() {
    }    
 
    constructor(props, context) {
         super(props);
         this.state = {
             navbar_expanded : false,
             showModalSidebar : false,
             message_body : "",
         }
 
         // save app context
         this.context = context;

         window.addEventListener('resize', this.handleResize)
    }

    handleResize = (e) => {
        //console.log(e);

        // check window mode and if it is changed (PC > Mobile, Mobile > PC), close sidebar
        if (window.innerWidth > 992) {
            if (this.state.showModalSidebar === true) {
                this.setState({showModalSidebar: false});
            }
        }
    }

    handleClickLogout = (e) => {
        //console.log(e);
        
        removeCookie('DB_ACCESS_TOKEN', {path : '/', secure : true});

        // process logout 
        this.context.isLoggedIn = false;

        // clear sessionStorage
        let sessionStorage = window.sessionStorage;
        sessionStorage.clear();

        // toggle collapse button 
        this.setState({
            navbar_expanded : false,
            showModalSidebar : false,
        });

        // navigate to home
        this.props.history.push("/");
    }

    handleClickToggle = (e) => {
        //console.log(e);
        this.setState({
            showModalSidebar : true
        });

    }

    handleSidebarClose = (e) => {
        this.setState({
            showModalSidebar : false,
        });

    }

    handleClickBrand = (e) => {
        this.props.history.push("/");

        // close sidebar
        this.setState({
            showModalSidebar : false,
        });
    }

    handleClickMonitoring = (e) => {
        this.props.history.push("/Monitoring");

        // close sidebar
        this.setState({
            showModalSidebar : false,
        });
    }

    handleClickSubmit = (e) => {
        this.props.history.push("/Submit");

        // close sidebar
        this.setState({
            showModalSidebar : false,
        });
    }

    handleClickMypage = (e) => {
        this.props.history.push("/Mypage");

        // close sidebar
        this.setState({
            showModalSidebar : false,
        });
    }

    render() {
        let submitActive = false;
        let monitoringActive = false;
        let mypageActive = false;

        let ret;

        // notes, redkur; since navbar is not updated properly when using history.push,
        // we'll update active attribute manually here by checking location's pathname 
        
        // get path name
        let pathname = this.props.history.location.pathname;
        if (pathname === "/") {
        }
        else {
            ret = pathname.indexOf("/Submit");
            if (ret >= 0) {
                submitActive = true;
            }

            ret = pathname.indexOf("/Monitoring");
            if (ret >= 0) {
                    monitoringActive = true;
            }

            ret = pathname.indexOf("/Mypage");
            if (ret >= 0) {
                mypageActive = true;
            }
        }

        if (this.context.isLoggedIn) {
            let show_toggle_image_white = false;
            let navcustom_font_color;
            let osean_logo;
            if ((this.context.current_page_name === "home") ||
                (this.context.current_page_name === "monitoring") ||
                (this.context.current_page_name === "login")) {
                // set white color
                navcustom_font_color = {
                    color: "#FFFFFF",
                };
                osean_logo = osean_logo1;
                show_toggle_image_white = true;
            }
            else {
                // set black color
                navcustom_font_color = {
                    color: "#000000",
                };
                osean_logo = osean_logo2;
            }

            let sidebar_navcustom_style_monitoring;
            let sidebar_navcustom_style_mypage;
            let sidebar_navcustom_style_submit;

            if (monitoringActive) {
                sidebar_navcustom_style_monitoring = {
                    color: "#35c4f0",
                    fontWeight: "bold",
                }
            }
            if (mypageActive) {
                sidebar_navcustom_style_mypage = {
                    color: "#35c4f0",
                    fontWeight: "bold",
                }
            }
            if (submitActive) {
                sidebar_navcustom_style_submit = {
                    color: "#35c4f0",
                    fontWeight: "bold",
                }
            }

            let div_mobile_navbar;
            if (this.context.user_info.user_type === "admin") {
                div_mobile_navbar = 
                    <div id="custom-sidebar-nav">
                        <Nav.Link className="custom-sidebar-nav-menu-item" style={sidebar_navcustom_style_monitoring} onClick={this.handleClickMonitoring}>Monitoring results</Nav.Link>
                        <Nav.Link className="custom-sidebar-nav-menu-item" style={sidebar_navcustom_style_mypage} onClick={this.handleClickMypage}>Mypage</Nav.Link>
                    </div>;
            }
            else if (this.context.user_info.user_type === "observer") {
                div_mobile_navbar = 
                    <div id="custom-sidebar-nav">
                        <Nav.Link className="custom-sidebar-nav-menu-item" style={sidebar_navcustom_style_monitoring} onClick={this.handleClickMonitoring}>Monitoring results</Nav.Link>
                        <Nav.Link className="custom-sidebar-nav-menu-item" style={sidebar_navcustom_style_submit} onClick={this.handleClickSubmit}>Submit</Nav.Link>
                        <Nav.Link className="custom-sidebar-nav-menu-item" style={sidebar_navcustom_style_mypage} onClick={this.handleClickMypage}>Mypage</Nav.Link>
                    </div>;
            }
            else if (this.context.user_info.user_type === "viewer") {
                div_mobile_navbar = 
                    <div id="custom-sidebar-nav">
                        <Nav.Link className="custom-sidebar-nav-menu-item" style={sidebar_navcustom_style_monitoring} onClick={this.handleClickMonitoring}>Monitoring results</Nav.Link>
                    </div>;
            }

            let div_navbar;
            if (this.context.user_info.user_type === "admin") {
                // show full navbar
                div_navbar = 
                    <Nav className="ml-auto navcustom-menu align-items-center">
                        <Nav.Link className="navcustom-menu-item" style={navcustom_font_color} as={Link} eventKey="1" to="/Monitoring" active={monitoringActive}>Monitoring results</Nav.Link>
                        <Nav.Link className="navcustom-menu-item" style={navcustom_font_color} as={Link} eventKey="3" to="/Mypage" active={mypageActive}>MyPage</Nav.Link>
                        <Nav.Link className="navcustom-menu-item-separator disabled" style={navcustom_font_color} >|</Nav.Link>
                        <Nav.Link className="navcustom-menu-item-logout" style={navcustom_font_color} onClick={this.handleClickLogout}>Logout</Nav.Link>
                    </Nav>;
            } 
            else if (this.context.user_info.user_type === "observer") {
                div_navbar = 
                    <Nav className="ml-auto navcustom-menu align-items-center">
                        <Nav.Link className="navcustom-menu-item" style={navcustom_font_color} as={Link} eventKey="1" to="/Monitoring" active={monitoringActive}>Monitoring results</Nav.Link>
                        <Nav.Link className="navcustom-menu-item" style={navcustom_font_color} as={Link} eventKey="2" to="/Submit" active={submitActive}>Submit</Nav.Link>
                        <Nav.Link className="navcustom-menu-item" style={navcustom_font_color} as={Link} eventKey="3" to="/Mypage" active={mypageActive}>MyPage</Nav.Link>
                        <Nav.Link className="navcustom-menu-item-separator disabled" style={navcustom_font_color} >|</Nav.Link>
                        <Nav.Link className="navcustom-menu-item-logout" style={navcustom_font_color} onClick={this.handleClickLogout}>Logout</Nav.Link>
                    </Nav>;
            }
            else if (this.context.user_info.user_type === "viewer") {
                div_navbar = 
                    <Nav className="ml-auto navcustom-menu align-items-center">
                        <Nav.Link className="navcustom-menu-item" style={navcustom_font_color} as={Link} eventKey="1" to="/Monitoring" active={monitoringActive}>Monitoring results</Nav.Link>
                        <Nav.Link className="navcustom-menu-item-separator disabled" style={navcustom_font_color} >|</Nav.Link>
                        <Nav.Link className="navcustom-menu-item-logout" style={navcustom_font_color} onClick={this.handleClickLogout}>Logout</Nav.Link>
                    </Nav>;
            }

            return (
                <div className="container">
                    <Modal id="custom-modal-sidebar" show={this.state.showModalSidebar} onHide={this.handleSidebarClose}>
                        <img src={image_close_button} 
                            width="24" height="24" 
                            id="custom-sidebar-close-button"
                            className="align-items-center" alt="" 
                            onClick={this.handleSidebarClose}>
                        </img>
                        <img src={osean_logo2} 
                            width="24" height="24" 
                            id="custom-sidebar-image-logo"
                            className="align-items-center" alt="" 
                            onClick={this.handleSidebarClose}>
                        </img>
                        <div id="custom-sidebar-brand">
                            <span id="custom-sidebar-brand-text" onClick={this.handleClickBrand}>Indonesia Marine Litter Monitoring Data Platform</span>
                            <hr className="custom-sidebar-brand-hr"/>
                        </div>
                        {div_mobile_navbar}
                        <div id="custom-sidebar-logout-button">
                            <Nav.Link className="custom-sidebar-nav-menu-item-logout" onClick={this.handleClickLogout}>Logout</Nav.Link>
                        </div>
                    </Modal>

                    <Navbar className="" collapseOnSelect expand='lg' variant='light'
                        onToggle={(e) => this.setState({navbar_expanded: e})}
                        expanded={this.state.navbar_expanded}
                    >
                        <div className="navcustom-brand" onClick={this.handleClickBrand}>
                            <img id="navcustom-brand-logo" alt="" src={osean_logo} width="30" height="30" className=""></img>
                            <div id="navcustom-brand-name" style={navcustom_font_color}>Indonesia Marine Litter Monitoring Data Platform</div>
                        </div>
                        <Navbar.Toggle aria-controls='responsive-navbar-nav' hidden={true} />
                        <Navbar.Collapse id='responsive-navbar-nav'>
                            {div_navbar}
                        </Navbar.Collapse>
                        <img src={toggle_image_black} hidden={show_toggle_image_white} width="24" height="24" className="custom-toggle-image align-items-center" alt="" onClick={this.handleClickToggle}></img>
                        <img src={toggle_image_white} hidden={!show_toggle_image_white} width="24" height="24" className="custom-toggle-image align-items-center" alt="" onClick={this.handleClickToggle}></img>
                    </Navbar>
                </div>
            );
        }
        else {
            // do not show navbar
            return (
                <div></div>
            );
        }
    }
}

export default withRouter(Navigation);