import React, { Component } from "react";
import { Form, Col, Tabs, Row, Tab } from "react-bootstrap";
import PropTypes from "prop-types";
import ResultRowByMaterial from "./ResultRowByMaterial";
import getDataCard from "../Common/Common";
import "./TransectTabs.scss";
import { getErrorMessage } from "../Common/Common";
import { onValidateFloat } from "../Common/Common";
import { onValidateDecimal } from "../Common/Common";
class TransectTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: this.props.transects[0].name,
        };

        this.setActiveKey = this.setActiveKey.bind(this);

        this.refLengthFrom = [React.createRef(), React.createRef(), React.createRef(), React.createRef()];
        this.refLengthTo = [React.createRef(), React.createRef(), React.createRef(), React.createRef()];
        this.refInvalidLength = [React.createRef(), React.createRef(), React.createRef(), React.createRef()];
        this.refWidth = [React.createRef(), React.createRef(), React.createRef(), React.createRef()];
    }

    setActiveKey(k) {
        this.setState({ activeKey: k });
    }

    getTabTitle(transects, transectName) {
        return (<React.Fragment>{transectName[0].toUpperCase() + transectName.slice(1)}</React.Fragment>);
    }


    validateTransectWidth(ti, e, sign) {
        onValidateFloat(e, sign);
        
        if(e.target.value === ""){
            this.refWidth[ti].current.value = "";
        }
        else{
            let value = parseFloat(e.target.value) * 5;
            this.refWidth[ti].current.value = Math.round(value * 1000) / 1000; // 소숫점 이하 3자리 까지
        }
        
    }

    validateTransectLength(ti, e, sign) {
        onValidateFloat(e, sign);
        
        if(e.target.value === ""){
            this.refLengthTo[ti].current.value = "";
        }
        else {
            this.refLengthTo[ti].current.value = parseFloat(e.target.value) + 5;

            if (this.refLengthFrom[ti].current.value && this.refLengthTo[ti].current.value) {
                this.refInvalidLength[ti].current.style.display = "none";
            } else if (this.props.validated) {
                // and has missing value
                this.refInvalidLength[ti].current.style.display = "block";
            }
        }
    }

    render() {
        const transects = Array.from(this.props.transects, (x) => x.name);
        let dataCard = getDataCard();

        return (
            <React.Fragment>
                <Form.Group as={Col} md={this.props.md} className="my-0">
                    <Tabs
                        id="result-tabs"
                        variant="tabs"
                        className="Result-Tabs"
                        activeKey={this.state.activeKey}
                        onSelect={(k) => this.setActiveKey(k)}
                        ref={this.props.forwardedRef}
                        transition={false}
                    >
                        {this.props.transects.map((transect, ti) => (
                            <Tab eventKey={transect.name} key={transect.name} title={this.getTabTitle(transects, transect.name)}>
                                <Row className="mx-0 Result-Tabs-Info-Rect">
                                    <Col sm="12" md="auto" className="mx-0 pl-0 Result-Tabs-Info-TransectNumber">
                                        <Form.Label className="Result-Tabs-Info-TransectNumber-Label">Transect number</Form.Label>
                                        <Form.Control
                                            required
                                            disabled={this.props.disabled}
                                            name="number"
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            min="0"
                                            step="1"
                                            type="text"
                                            className={this.state.disabled ? "Result-Tabs-Info-TransectNumber-Input-Disabled" : "Result-Tabs-Info-TransectNumber-Input"}
                                            defaultValue={transect.number ? transect.number : undefined}
                                            onChange={(e) => onValidateDecimal(e, false)}
                                        />
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Col>

                                    <Col sm="12" md="5" className="pl-0 Result-Tabs-Info-TransectLength">
                                        <Row className="my-0 py-0">
                                            <Col>
                                                <Form.Label className="Result-Tabs-Info-TransectLength-Label">Transect length</Form.Label>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex">
                                            <Col className="pr-0">
                                                <div className="d-flex align-middle">
                                                    <Form.Control
                                                        required
                                                        className="Information-Time-Input"
                                                        disabled={this.props.disabled}
                                                        name="lengthFrom"
                                                        inputMode="decimal"
                                                        pattern="[0-9.]*"
                                                        min="0"
                                                        step="1"
                                                        type="text"
                                                        ref={this.refLengthFrom[ti]}
                                                        defaultValue={transect.lengthFrom ? transect.lengthFrom : undefined}
                                                        onChange={(e) => this.validateTransectLength(ti, e, false)}
                                                        onInvalid={(e) => (this.refInvalidLength[ti].current.style.display = "block")}
                                                    />
                                                    <Form.Label className="mr-0 pr-0 Result-Tabs-Info-TransectLength-M">m</Form.Label>
                                                </div>
                                            </Col>

                                            <Col
                                                className="mx-0 px-0 align-middle"
                                                style={{
                                                    maxWidth: "20px",
                                                    paddingLeft: "8px",
                                                    paddingRight: "8px",
                                                }}
                                            >
                                                <Form.Label className="Information-Time-And">~</Form.Label>
                                            </Col>

                                            <Col className="pl-0">
                                                <div className="d-flex align-middle">
                                                    <Form.Control
                                                        required
                                                        className="Information-Time-Input"
                                                        disabled={true}
                                                        name="lengthTo"
                                                        inputMode="decimal"
                                                        pattern="[0-9.]*"
                                                        min="0"
                                                        step="1"
                                                        type="text"
                                                        ref={this.refLengthTo[ti]}
                                                        defaultValue={transect.lengthTo ? transect.lengthTo : undefined}
                                                    />
                                                    <Form.Label className="mr-0 pr-0 Result-Tabs-Info-TransectLength-M">m</Form.Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Form.Control.Feedback ref={this.refInvalidLength[ti]} type="invalid">
                                            {getErrorMessage("required")}
                                        </Form.Control.Feedback>
                                    </Col>

                                    <Col md="2" className="pl-0 Result-Tabs-Info-Width">
                                        <Form.Label className="Result-Material-Weight-Label">Width(m)</Form.Label>
                                        <Form.Control
                                            required
                                            disabled={this.props.disabled}
                                            name="width"
                                            inputMode="decimal"
                                            pattern="[0-9.]*"
                                            min="0"
                                            step="0.1"
                                            type="text"
                                            className="Information-Time-Input Result-Tabs-Info-Width-Input"
                                            defaultValue={transect.width ? transect.width : undefined}
                                            onChange={(e) => this.validateTransectWidth(ti, e, false)}
                                        />
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Col>

                                    <Col md="2" className="pl-0 Result-Tabs-Info-Area">
                                        <Form.Label className="Result-Material-Weight-Label">Area(㎡)</Form.Label>
                                        <Form.Control
                                            required
                                            disabled={true}
                                            name="area"
                                            inputMode="decimal"
                                            pattern="[0-9.]*"
                                            min="0"
                                            step="0.1"
                                            type="text"
                                            className="Information-Time-Input Result-Tabs-Info-Area-Input"
                                            ref={this.refWidth[ti]}
                                            defaultValue={transect.area ? transect.area : undefined}
                                        />
                                    </Col>
                                </Row>

                                <hr className="d-none-on-md-25" />

                                {dataCard.materials.map((material, mi) =>
                                    material.types.map((type, ti) =>
                                        type.items.map((item, ii) => (
                                            <ResultRowByMaterial
                                                key={material.name + type.name + item.id}
                                                materialIndex={mi}
                                                typeRowSpan={ii === 0 ? type.items.length : 0}
                                                material={material.name}
                                                type={type.name}
                                                id={"Item" + ii}
                                                disabled={this.props.disabled}
                                                item={item}
                                                transect={transect}
                                                typeIndex={ti}
                                                itemIndex={ii}
                                                typeCount={type.items.length}
                                            />
                                        ))
                                    )
                                )}
                            </Tab>
                        ))}
                    </Tabs>
                </Form.Group>
            </React.Fragment>
        );
    }
}

TransectTabs.propTypes = {
    md: PropTypes.string,
    disabled: PropTypes.bool,
    transects: PropTypes.array,
    forwardedRef: PropTypes.any,
    validated: PropTypes.bool,
};

// @see https://ko.reactjs.org/docs/forwarding-refs.html#forwarding-refs-in-higher-order-components
// Note. 이게 어떻게 동작하는지 이해가 안됨.
function TransectTabsWrap(WrappedComponent) {
    class TransectTabsRef extends React.Component {
        render() {
            const { forwardedRef, ...rest } = this.props;

            return <WrappedComponent ref={forwardedRef} {...rest} />;
        }
    }

    return React.forwardRef((props, ref) => {
        return <TransectTabsRef {...props} forwardedRef={ref} />;
    });
}

export default TransectTabsWrap(TransectTabs);
