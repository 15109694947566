import React from "react";
import { Link } from 'react-router-dom';

// import images
import image_home_download from '../images/27-icon-download.png';

import image_home_banner_1 from '../images/image_home_banner_1.png';
import image_home_banner_2 from '../images/image_home_banner_2.png';
import image_home_banner_3 from '../images/image_home_banner_3.png';
import image_home_banner_4 from '../images/image_home_banner_4.png';
import image_icon_chevron_right from '../images/27-icon-chevron-right.png';


//import files_1 from '../files/1. monitoring_manual_eng.pdf';    // manual
//import files_2 from '../files/2021_indonesia_data_card_eng.xlsx';  // monitoring card
//import files_3 from '../files/2. Result_of_beach_litter_survey_at_Labuan_Bajo_Indonesia_2019.pdf'; // report 2019
//import files_4 from '../files/2021_indonesia_data_card_eng.xlsx';   // report 2021

import Login from "./Login";
import { AppContext } from "../AppContext";

import "./Home.css";


class Home extends React.Component {
    static contextType = AppContext;

    componentDidMount() {
    }

    constructor(props, context) {
        super(props);

        // save app context
        this.context = context;

        // check current page
        if (this.context.isLoggedIn) {
            if (this.context.current_page_name !== 'home') {
                this.props.change('home');
            }
        }
        else {
            if (this.context.current_page_name !== 'login') {
                this.props.change('login');
            }
        }
    }

    render() {
        if (this.context.isLoggedIn) {
            // show default home
            // https://www.tutorialrepublic.com/twitter-bootstrap-tutorial/bootstrap-grid-system.php
            return (
                <div className="">
                    <div className="div-home-body">
                        <div className="div-home-body-message">
                            <span>Strengthening and Improving for Marine Litter Response in Indonesia</span>
                        </div>
                        <Link to="/Monitoring">
                            <div className="div-home-body-monitoring-button text-center align-items-center">
                                    <span id="home_monitoring_result1">Monitoring results</span>
                                    <img id="home_monitoring_result2" src={image_icon_chevron_right} className="img-fluid" alt="Responsive"></img>
                                </div>
                        </Link>
                    </div>
                    <div id="div-home-footer">
                        <div className="row justify-content-center text-center align-items-center link_row" id="div-home-download">
                            <div className="col-12 col-lg-4 link_col">
                                <span className="link_label">Manual</span>
                                <a className="link_download_button" href={process.env.PUBLIC_URL + "/1. monitoring_manual_eng.pdf"}>
                                    <img src={image_home_download} className="img-fluid" alt="Responsive"></img>
                                </a>
                            </div>
                            <div className="col-12 col-lg-4 link_col">
                                <span className="link_label">Monitoring Card</span>
                                <a className="link_download_button" href={process.env.PUBLIC_URL + "/Monitoring Card.pdf"}>
                                    <img src={image_home_download} className="img-fluid" alt="Responsive"></img>
                                </a>
                            </div>
                            <div className="col-12 col-lg-4 link_col">
                                <span className="link_label">Report 2019</span>
                                <a className="link_download_button" href={process.env.PUBLIC_URL + "/2. Result_of_beach_litter_survey_at_Labuan_Bajo_Indonesia_2019.pdf"}>
                                    <img src={image_home_download} className="img-fluid" alt="Responsive"></img>
                                </a>
                            </div>
                            {/* <div className="col-12 col-lg-3 link_col">
                                <span className="link_label">Report 2021</span>
                                <a className="link_download_button" href={files_4}>
                                    <img src={image_home_download} className="img-fluid" alt="Responsive"></img>
                                </a>
                            </div> */}
                        </div>
                        <div className="row justify-content-center text-center align-items-center" id="div-home-link">
                            <div className="col-6 col-lg-2">
                                <a href="https://www.mof.go.kr">
                                    <img src={image_home_banner_1} className="img-fluid img-banner" alt="Responsive"></img>
                                </a>
                            </div>
                            <div className="col-6 col-lg-2">
                                <a href="https://www.koem.or.kr">
                                    <img src={image_home_banner_2} className="img-fluid img-banner" alt="Responsive"></img>
                                </a>
                            </div>
                            <div className="col-6 col-lg-2">
                                <a href="http://www.osean.net/">
                                    <img src={image_home_banner_3} className="img-fluid img-banner" alt="Responsive"></img>
                                </a>
                            </div>
                            <div className="col-6 col-lg-2">
                                <a href="https://maritim.go.id/">
                                    <img src={image_home_banner_4} className="img-fluid img-banner" alt="Responsive"></img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            // show login comonent
            return (
                <Login />
            )
        }
    }
}

export default Home;