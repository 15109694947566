import React, { Component } from "react";
import SubmitMonitoring from "./Submit/SubmitMonitoring";
import { getSubmitSchema, fillDefaultValue } from "./Common/Common";
import { req_post } from "../rest";
import { Spinner, Col, Row } from "react-bootstrap";
import { AppContext } from '../AppContext';

export default class Submit extends Component {
	static contextType = AppContext;

	constructor(props, context) {
		super(props);

		let uniqueKey = "";
		if (this.props.location.state !== undefined) {
			// value exists only when history.push is used
			uniqueKey = this.props.location.state.uniqueKey;
		}

		let data;
		if(this.props.location.state !== undefined && this.props.location.state.excelData !== undefined){
			data = this.props.location.state.excelData;
		}
		else{
			data = getSubmitSchema(4);
		}
			
		if (this.props.location.pathname === "/Submit2") {
			// auto fill random value
			data = fillDefaultValue(data);
		}

		this.state = {
			isLoading: true,
			sites: [],
			uniqueKey: uniqueKey,
			data: data,
		};

		// check login state 
		if (context.isLoggedIn === false) {
			// re-direct to main page 
			this.props.history.push("/");
		}

		// check user type
		if ((context.user_info.user_type !== "admin") && (context.user_info.user_type !== "observer")) {
			// re-direct to main page 
			console.log("user_type: " + context.user_info.user_type + ", not allowed to access this page");
			this.props.history.push("/");
		}

		this.context = context;

		this._isMount = false;

		// check current page
		if (this.context.current_page_name !== 'submit') {
			this.props.change('submit');
		}
	}

	componentWillUnmount() {
		this._isMount = false;
	}

	componentDidMount() {
		if (this.context.isLoggedIn === false) { return; }
		this._isMount = true;

		// get data using uniqueKey
		let uniqueKey = this.state.uniqueKey;

		if (uniqueKey === "" && this.context.user_info.user_type === "admin") {
			console.log("admin user cannot submit data (view or edit allowed)");
			this.props.history.push("/");
			return;
		}

		// create request data
		const requestData = new FormData();
		requestData.append("uniqueKey", uniqueKey);

		req_post(this.context.server_ip_address + "/flask/checkout_monitoring", requestData, undefined, (error, response) =>{

			if(error){

				console.error(error);

				if (this._isMount) {
					this.setState({ isLoading: false });
				}

				return;
			}

			if (!this._isMount) return;

			const result = response.data;
			// check result
			if (result["status"] !== "Success") {
				//   console.warn(result["message"]);
				this.setState({ sites: result.sites, isLoading: false });
				return;
			}

			// check data.site is included in sites
			let found = false;
			let data_site_name = result.message.site;
			for (let index = 0; index < result.sites.length; index++) {
				if (result.sites[index].site_name === data_site_name) {
					found = true;
					break;
				}
			}

			if (!found && result.message.site) {
				// add it to sites 
				let _site_info = {};
				_site_info.site_name = result.message.site;
				_site_info.latitude = result.message.latitude;
				_site_info.longitude = result.message.longitude;
				result.sites.push(_site_info);
			}

			this.setState({ sites: result.sites, data: result.message, isLoading: false });
		});
	}

	render() {
		if (this.state.isLoading) {
			return (
				<Row className="h-100 row align-items-center">
					<Col className="text-center">
						<Spinner animation="border" role="status"></Spinner>
					</Col>
				</Row>
			);
		} else {
			return (
				<div className="sub-container">
					<SubmitMonitoring data={this.state.data} sites={this.state.sites} />
				</div>
			);
		}
	}
}
