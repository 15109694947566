import React from "react";
import { withRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import { Nav } from "react-bootstrap";

import Member from "./Mypage/Member";
import MemberEdit from "./Mypage/Member/MemberEdit";
import MemberCreate from "./Mypage/Member/MemberCreate";

import Data from "./Mypage/Data";
import Statistics from "./Mypage/Statistics";
import Setting from "./Mypage/Setting";
import History from "./Mypage/History";

import { AppContext } from '../AppContext';

import "./Mypage.css";


class Mypage extends React.Component {
    static contextType = AppContext;

    componentDidMount() {
    }    
 
    constructor(props, context) {
        super(props);
        this.state = {
        }

        // check login state 
        if (context.isLoggedIn === false) {
            // re-direct to main page 
            this.props.history.push("/");
        }

        // check user type
        if ((context.user_info.user_type !== "admin") && (context.user_info.user_type !== "observer")) {
            // re-direct to main page 
            console.log("user_type: " + context.user_info.user_type + ", not allowed to access this page");
            this.props.history.push("/");
        }

        // save app context
        this.context = context;

        // check current page
        if (this.context.current_page_name !== 'mypage') {
            this.props.change('mypage');
        }
    }
 
    render() {
        const { path, url } = this.props.match;

        // get path name
        let memberActive = false;
        let dataActive = false;
        let statisticsActive = false;
        let settingActive = false;
        let historyActive = false;

        let pathname = this.props.history.location.pathname;
        let ret;

        // find matched string to set link's active state
        ret = pathname.indexOf("/Member");
        if (ret >= 0) {
            memberActive = true;
        }
        ret = pathname.indexOf("/Data");
        if (ret >= 0) {
                dataActive = true;
        }
        ret = pathname.indexOf("/Statistics");
        if (ret >= 0) {
            statisticsActive = true;
        }
        ret = pathname.indexOf("/Setting");
        if (ret >= 0) {
            settingActive = true;
        }
        ret = pathname.indexOf("/History");
        if (ret >= 0) {
            historyActive = true;
        }
        // check user_type 
        let router_default, sidebar_links;
        
        if (this.context.user_info.user_type === "admin") {
            sidebar_links = 
                <div className="sidebar">
                    <div className="container">
                        <Nav.Link className="" as={Link} eventKey="1" to={`${url}/Member`} active={memberActive}>Member</Nav.Link>
                        <Nav.Link className="" as={Link} eventKey="1" to={`${url}/Data`} active={dataActive}>Data</Nav.Link>
                        <Nav.Link className="" as={Link} eventKey="1" to={`${url}/Statistics`} active={statisticsActive}>Statistics</Nav.Link>
                        <Nav.Link className="" as={Link} eventKey="1" to={`${url}/Setting`} active={settingActive}>Setting</Nav.Link>
                    </div>
                </div>;
            router_default = <Route exact path="/Mypage"><Redirect to="/Mypage/Member" /></Route>;
        }
        //else if (this.context.user_info.user_type === "observer") {
        else {
            sidebar_links = 
                <div className="sidebar">
                    <div className="container">
                        <Nav.Link className="" as={Link} eventKey="1" to={`${url}/Mypage/History`} active={historyActive}>Monitoring History</Nav.Link>
                    </div>
                </div>;
            router_default = <Route exact path="/Mypage"><Redirect to="/Mypage/History" /></Route>;
        }

        return (
            <div>
                    <div>
                        {sidebar_links}
                    </div>
                    <div className="content">
                        <Switch>
                            {router_default}
                            <Route exact path={`${path}/Member`} render={(props) => <Member {...props} key={Date.now()}/>}></Route>
                            <Route exact path={`${path}/MemberEdit`} render={(props) => <MemberEdit {...props} key={Date.now()}/>}></Route>
                            <Route exact path={`${path}/MemberCreate`} render={(props) => <MemberCreate {...props} key={Date.now()}/>}></Route>
                            <Route exact path={`${path}/Data`} render={(props) => <Data {...props} key={Date.now()}/>}></Route>
                            <Route exact path={`${path}/Statistics`} render={(props) => <Statistics {...props} key={Date.now()}/>}></Route>
                            <Route exact path={`${path}/Setting`} render={(props) => <Setting {...props} key={Date.now()}/>}></Route>
                            <Route exact path={`${path}/History`} render={(props) => <History {...props} key={Date.now()}/>}></Route>
                            <Route render={() => <Redirect to="/Mypage" />} />
                        </Switch>
                    </div>
            </div>
        );
    }
}

export default withRouter(Mypage);