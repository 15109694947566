import _ from "lodash";
import * as CryptoJS from 'crypto-js';

export function getFlaskServerAddresss() {
  if(process.env.REACT_APP_FLASK_SERVER_ADDR == undefined){
    return "http://localhost:5000";
  }else{
    return process.env.REACT_APP_FLASK_SERVER_ADDR;
  }
}

export function getWindSpeeds() {
  return ["No", "Weak", "Moderate", "Strong"];
}

export function getWeathers() {
  return ["Sunny", "Cloud", "Rain", "Snow"];
}

export function getWindDirection() {
  return ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
}

/**
 * 한 Transect 마다 Picture 영역에 올릴수 있는 최대 사진 갯수를 반환합니다.
 *
 * @returns 한 Transect 마다 Picture 영역에 올릴수 있는 최대 사진 갯수
 */
export function getTransectMaxPictureCount() {
  return 6;
}

/**
 * Submit Monitoring 폼에서 업로드 되는 개별 이미지의 최대 크기 (최대 용량)
 * Note. in Byte
 */
export function getSubmitMonitoringPictureSizeLimit() {
  return 1024 * 1024 * 100; // 100MB
}

/**
 * 이미지의 경로를 반환합니다.
 * 
 * @param {*} file file 의 정보를 담고 있는 객체
 * @returns 이미지 경로. 아직 서버에 업로드 되지 않은 경우, 원본 경로(client pc 의 경로) 를 그대로 사용합니다.
 */
export function getUploadFileUrl(file) {
  return file.url || `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_BUCKET}/empty.png`;
}

/**
 * Thumbnail 이미지의 경로를 반환합니다.
 * 
 * @param {*} file file 의 정보를 담고 있는 객체
 * @returns thumbnail 경로. 아직 서버에 업로드 되지 않은 경우, 원본 경로(client pc 의 경로) 를 그대로 사용합니다.
 */
export function getUploadFileThumbUrl(file) {
    return file.thumbnail || file.url;  
}

export function getErrorMessage(error) {
    switch (error) {
        case "required":
            return "Please fill out this field.";
        default:
            return "";
    }
}

/**
 * 입력된 값이 실수가 아니라면, 실수 부분까지만 잘라서 입력된 값으로 대체 합니다.
 *
 * `-38..` 가 입력된 경우 입력값을 `-38.` 로 변경
 * @param {*} e event 객체
 */
 export function onValidateFloat(e, sign=false) {
    let value = e.target.value;
    let sym = (value[0] === '-' || value[0] === '+')?value[0]:'';
    value = value.replace(/[^0-9.]+/g, '');  // 숫자와 '.' 만 남김
    let dot_i = value.indexOf('.'); // 첫번째 '.' 위치
    value = value.replace(/\D/g, '');  // 숫자만 남김
    value = (dot_i !== -1)?value.slice(0, dot_i)+'.'+value.slice(dot_i):value;
    value = (sign)?sym+value:value;  // sign (+/-) 처리

    e.target.value = value;
}

/**
 * 입력된 값이 정수가 아니라면, 정수 부분까지만 잘라서 입력된 값으로 대체 합니다.
 *
 * `-38a` 가 입력된 경우 입력값을 `-38` 로 변경
 * @param {*} e event 객체
 */
export function onValidateDecimal(e, sign=false) {
    let value = e.target.value;
    let sym = (value[0] === '-' || value[0] === '+')?value[0]:'';
    value = value.replace(/\D/g, '');
    value = (sign)?sym+value:value;  // sign (+/-) 처리

    e.target.value = value;
}

function getRandomIntInclusive(min_, max_, pad0=false) {
    min_ = Math.ceil(min_);
    max_ = Math.floor(max_);
    let r = Math.floor(Math.random() * (max_ - min_ + 1)) + min_; //최댓값도 포함, 최솟값도 포함

    let rl = r.toString().length;  // result 숫자의 자릿수
    let ml = max_.toString().length; // 최대값 숫자의 자릿수
    if (pad0 && (rl < ml)) {
        let pads = ml - rl;
        return '0'.repeat(pads)+r;
    } else {
        return r;
    }
}

/**
 * 파라메터로 받은 객체에 임의의 값들을 채워서 반환합니다.
 * 
 * @param {object} schema 빈 값들을 가지고 있는, monitoring submit 데이터 객체
 * @returns 기본적인 (임의의) 값이 채워진 monitoring submit 데이터 객체  
 */
export function fillDefaultValue(schema) {
    const min = Math.ceil(0);
    const max = Math.floor(1000);

    const orgs = ["Wonka Industries", "Acme Corp.", "Stark Industries", "Ollivander's Wand Shop", "Gekko & Co", "Wayne Enterprises", "Cyberdyne Systems", "Genco Pura Olive Oil Company", "The New York Inquirer", "DEVGURU", "Sterling Cooper", "Soylent"];
    const lnames = ["Kim", "Lee", "Park", "Ahn", "Kwak", "Song", "Oh", "Choi", "Yu", "Jo", "Jeon", "Jee"];
    const fnames = ["John", "Juililard", "Katherine", "Sienna", "Rachel", "Francis", "Kay", "Evan", "Conner", "Joshua", "Anthony", "Harvey", "James", "Adam", "Benjamin", "Enji", "Leo", "Dynne", "Jeffrey"];

    let lname = Math.floor(Math.random()*lnames.length);
    let fname = Math.floor(Math.random()*fnames.length);

    schema.date = `2021-${getRandomIntInclusive(1, 12, true)}-${getRandomIntInclusive(1, 28, true)}`;
    schema.startTime = "09:00";
    schema.endTime = "18:00";
    schema.organization = orgs[Math.floor(Math.random()*orgs.length)];
    schema.manager = `${fnames[fname]} ${lnames[lname]}`;
    schema.numberOfParticipant = `${getRandomIntInclusive(1, 10)}`;
    schema.temperature = `${getRandomIntInclusive(1, 40)}.${getRandomIntInclusive(0, 9)}`;
    schema.windDirection = "NE";
    schema.windSpeed = "No";
    schema.weather = "Sunny";
    schema.latestTimeOfHighTide = "18:30";
    schema.others = "This is Dummy Submit Data";
    schema.beforeFile = {
        name: "beforeFile-DUMMY.jpg",
        url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_BUCKET}/empty.png`,
        mime: "",
        size: "",
        key: "",
        thumbnail: "",
    };
    schema.afterFile = {
        name: "afterFile-DUMMY.jpg",
        url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_BUCKET}/empty.png`,
        mime: "",
        size: "",
        key: "",
        thumbnail: "",
    };
    schema.transects.forEach(transect => {
        transect.width = Math.floor(Math.random() * (max - min)) + min;
        transect.number = Math.floor(Math.random() * (max - min)) + min;
        transect.lengthFrom = Math.floor(Math.random() * (max - min)) + min;
        transect.lengthTo = transect.lengthFrom + 5;
        transect.area = transect.width * 5;
        transect.cardFile = {
            name: "cardFile-DUMMY.jpg",
            url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_BUCKET}/empty.png`,
            mime: "",
            size: "",
            key: "",
            thumbnail: "",
        };
        transect.pictureFiles.push({
            name: "pictureFile-DUMMY.jpg",
            url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_BUCKET}/empty.png`,
            mime: "",
            size: "",
            key: "",
            thumbnail: "",
        });
        transect.result.materials.forEach(material => {
            let v = Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
            material.weight = v;
            material.types.forEach(type => {
                type.items.forEach(item => {
                    let v = Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
                    item.count = v;
                });
            });
        });
    });

    return schema;
}

export function getSubmitSchema(transectCount) {
  let schema = {
    uniqueKey: undefined, // "adlkjsfiewjljkf...",
    date: "", //"0000-00-00",
    startTime: "", //"00:00",
    endTime: "", //"00:00",
    site: "", //"Site1",
    confirm_state: "not_submitted",
    organization: "", //"OSEAN",
    manager: "", //"John",
    numberOfParticipant: "", //3,
    longitude: "", //300.4,
    latitude: "", //400.3,
    temperature: "", //39.3,
    windDirection: "", //"NE",
    windSpeed: "", //"Weak",
    weather: "", //"Cloud",
    latestTimeOfHighTide: "",
    others: "",
    beforeFile: {
      name: "", //"empty.png",
      url: "", //"res/empty.png",
    },
    transects: [
      {
        name: "Transect1",
        number: "",
        lengthFrom: "",
        lengthTo: "",
        width: "", //10,
        area: "",
        cardFile: {
          name: "", //"empty.png",
          url: "", //"res/empty.png",
          mime: "",
          size: "",
          key: "",
          thumbnail: "",
        },
        pictureFiles: [
          //   {
          //     name: "", //"empty.png",
          //     url: "", //"res/empty.png",
          //   },
        ],
        result: {
          materials: [
            {
              name: "Plastic",
              weight: undefined,
              types: [
                {
                  name: "Hard",
                  items: [
                    { id: "Plastic-Hard-1", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-2", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-3", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-4", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-5", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-6", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-7", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-8", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-9", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-10", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-11", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-12", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-13", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-14", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-15", count: undefined },  // count: 0 },
                    { id: "Plastic-Hard-16", count: undefined },  // count: 0 },
                  ],
                },
                {
                  name: "Foamed",
                  items: [
                    { id: "Plastic-Foamed-1", count: undefined },  // count: 0 },
                    { id: "Plastic-Foamed-2", count: undefined },  // count: 0 },
                    { id: "Plastic-Foamed-3", count: undefined },  // count: 0 },
                    { id: "Plastic-Foamed-4", count: undefined },  // count: 0 },
                    { id: "Plastic-Foamed-5", count: undefined },  // count: 0 },
                    { id: "Plastic-Foamed-6", count: undefined },  // count: 0 },
                    { id: "Plastic-Foamed-7", count: undefined },  // count: 0 },
                  ],
                },
                {
                  name: "Fiber",
                  items: [
                    { id: "Plastic-Fiber-1", count: undefined },  // count: 0 },
                    { id: "Plastic-Fiber-2", count: undefined },  // count: 0 },
                    { id: "Plastic-Fiber-3", count: undefined },  // count: 0 },
                    { id: "Plastic-Fiber-4", count: undefined },  // count: 0 },
                    { id: "Plastic-Fiber-5", count: undefined },  // count: 0 },
                    { id: "Plastic-Fiber-6", count: undefined },  // count: 0 },
                    { id: "Plastic-Fiber-7", count: undefined },  // count: 0 },
                  ],
                },
                {
                  name: "Film",
                  items: [
                    { id: "Plastic-Film-1", count: undefined },  // count: 0 },
                    { id: "Plastic-Film-2", count: undefined },  // count: 0 },
                    { id: "Plastic-Film-3", count: undefined },  // count: 0 },
                    { id: "Plastic-Film-4", count: undefined },  // count: 0 },
                    { id: "Plastic-Film-5", count: undefined },  // count: 0 },
                  ],
                },
                {
                  name: "Others",
                  items: [
                    { id: "Plastic-Others-1", count: undefined },  // count: 0 },
                    { id: "Plastic-Others-2", count: undefined },  // count: 0 },
                  ],
                },
              ],
            },
            {
              name: "Wood",
              weight: undefined,
              types: [
                {
                  name: "",
                  items: [{ id: "Wood-1", count: undefined }],  // count: 0 }],
                },
              ],
            },
            {
              name: "Metal",
              weight: undefined,
              types: [
                {
                  name: "",
                  items: [{ id: "Metal-1", count: undefined }],  // count: 0 }],
                },
              ],
            },
            {
              name: "Natural fiber",
              weight: undefined,
              types: [
                {
                  name: "",
                  items: [{ id: "Natural fiber-1", count: undefined }],  // count: 0 }],
                },
              ],
            },
            {
              name: "Glass",
              weight: undefined,
              types: [
                {
                  name: "",
                  items: [{ id: "Glass-1", count: undefined }],  // count: 0 }],
                },
              ],
            },
            {
              name: "Rubber",
              weight: undefined,
              types: [
                {
                  name: "",
                  items: [{ id: "Rubber-1", count: undefined }],  // count: 0 }],
                },
              ],
            },
            {
              name: "Paper",
              weight: undefined,
              types: [
                {
                  name: "",
                  items: [{ id: "Paper-1", count: undefined }],  // count: 0 }],
                },
              ],
            },
            {
              name: "Others and mixed material",
              weight: undefined,
              types: [
                {
                  name: "",
                  items: [{ id: "Others-1", count: undefined }],  // count: 0 }],
                },
              ],
            },
          ],
        },
      },
    ],
    afterFile: {
      name: "", //"empty.png",
      url: "", //"res/empty.png",
      mime: "",
      size: "",
      key: "",
      thumbnail: "",
    },
    etcFiles: [
      //   {
      //     name: "", //"empty.png",
      //     url: "", //"res/empty.png",
      //   },
    ],
  };

  for (let i = 0; i < transectCount; ++i) {
    if (i === 0) continue; // 이미 schema 에 포함되어 있는 index는 무시.
    schema.transects.push(_.cloneDeep(schema.transects[0]));
    schema.transects[i].name = "Transect" + (i + 1);
  }

  return schema;
}

export default function getDataCard() {
  return {
    materials: [
      {
        name: "Plastic",
        types: [
          {
            name: "Hard",
            items: [
              { id: "Plastic-Hard-1", name: "Beverage bottle,  Disposable Cup, lid" },
              { id: "Plastic-Hard-2", name: "Disposable spoon, fork, straw, plastic chopstick, etc" },
              {
                id: "Plastic-Hard-3",
                name: "Food container (plastic mika, [tupperware plastic], soy sauce bottle, red pepper paste bottle, kitchen herb bottle, cup etc)",
              },
              { id: "Plastic-Hard-4", name: "Toy, doll, office supply, hobbyist amusement supply" },
              { id: "Plastic-Hard-5", name: "Detergent (for kitchen, washing clothes, bath supply, toiletries, cotton buds, etc)" },
              { id: "Plastic-Hard-6", name: "Packaging strap (rapia strap, tambang strap and other)" },
              { id: "Plastic-Hard-7", name: "Lighter" },
              { id: "Plastic-Hard-8", name: "Fire work supply, firecracker" },
              { id: "Plastic-Hard-9", name: "Aquaculture chemical bottle" },
              { id: "Plastic-Hard-10", name: "Plastic buoy" },
              { id: "Plastic-Hard-11", name: "Trap (bottle for eel trap, etc)" },
              { id: "Plastic-Hard-12", name: "Fake lure, Fluorescent bobber, bait container" },
              { id: "Plastic-Hard-13", name: "Pesticides bottle" },
              { id: "Plastic-Hard-14", name: "Plastic related medical (Syringe, medicine bottle, medicine pack, face mask, face shield, medical gloves, etc)" },
              { id: "Plastic-Hard-15", name: "Other plastic products (boxes, water proof sheet, pipe, hose etc)" },
              { id: "Plastic-Hard-16", name: "Plastic fragment" },
            ],
          },
          {
            name: "Foamed",
            items: [
              { id: "Plastic-Foamed-1", name: "Styrofoam Buoy" },
              {
                id: "Plastic-Foamed-2",
                name: "Styrofoam food container (food - fish, chicken or meat - container, cup noodle container, fruit wrap)",
              },
              { id: "Plastic-Foamed-3", name: "Styrofoam packaging filling (for home appliances: tv, refrigerator, any electronic devices, other)" },
              { id: "Plastic-Foamed-4", name: "Sandals/Flipper, shoes" },
              { id: "Plastic-Foamed-5", name: "Cigarette butt" },
              { id: "Plastic-Foamed-6", name: "Others (sponge, wipe, etc)" },
              { id: "Plastic-Foamed-7", name: "Polystyrene fragment" },
            ],
          },
          {
            name: "Fiber",
            items: [
              { id: "Plastic-Fiber-1", name: "String, wrapping strap" },
              { id: "Plastic-Fiber-2", name: "Rope" },
              { id: "Plastic-Fiber-3", name: "Net" },
              { id: "Plastic-Fiber-4", name: "Fishing line" },
              {
                id: "Plastic-Fiber-5",
                name: "Clothes, fabric, socks, comforter/blanket, sofa cover, carpet, etc (only synthetic fiber)",
              },
              { id: "Plastic-Fiber-6", name: "Others (sanitary pads, dipers, wipe, etc)" },
              { id: "Plastic-Fiber-7", name: "Fiber fragment" },
            ],
          },
          {
            name: "Film",
            items: [
              { id: "Plastic-Film-1", name: "Plastic bag" },
              { id: "Plastic-Film-2", name: "Plastic sachet, transparant plastic wrapper, other thin plastic wrapper" },
              { id: "Plastic-Film-3", name: "Film ballon" },
              { id: "Plastic-Film-4", name: "Others (disposable plastic globe, duck tape etc)" },
              { id: "Plastic-Film-5", name: "Film fragment" },
            ],
          },
          {
            name: "Others",
            items: [
              { id: "Plastic-Others-1", name: "Other plastics (tampons, etc)" },
              { id: "Plastic-Others-2", name: "Other plastic fragment" },
            ],
          },
        ],
      },
      {
        name: "Wood",
        types: [
          {
            name: "",
            items: [
              {
                id: "Wood-1",
                name: "spoon, fork and chopstick from wood, ice cream bar, wood pallette, construction wood, fishery/aquaculture wood, processed wood, clove ciggarete butts etc",
              },
            ],
          },
        ],
      },
      {
        name: "Metal",
        types: [
          {
            name: "",
            items: [
              {
                id: "Metal-1",
                name: "Food/beverage can, gas can, fishing hook, spring trap, spray can, nail, iron core, metal pipe, caps etc",
              },
            ],
          },
        ],
      },
      {
        name: "Natural fiber",
        types: [
          {
            name: "",
            items: [
              {
                id: "Natural fiber-1",
                name: "Clothes, cap, socks, fabric, gloves, cotton sofa cover, cotton gloves etc (only natural fiber)",
              },
            ],
          },
        ],
      },
      {
        name: "Glass",
        types: [
          {
            name: "",
            items: [
              {
                id: "Glass-1",
                name: "Beverage bottle, bulb, pesticide bottle, Fluorescent bulb, glass fragment, bulb etc",
              },
            ],
          },
        ],
      },
      {
        name: "Rubber",
        types: [
          {
            name: "",
            items: [
              {
                id: "Rubber-1",
                name: "Tyre, rubber ballon, rubber gloves, balls,rubber fragment, rubber band, rubber gloves, condoms,  etc",
              },
            ],
          },
        ],
      },
      {
        name: "Paper",
        types: [
          {
            name: "",
            items: [
              {
                id: "Paper-1",
                name: "Paper cup, milk carton, fire cracker wrap made of paper, pack of cigarette, carboard, newspapers, tissue etc",
              },
            ],
          },
        ],
      },
      {
        name: "Others and mixed material",
        types: [
          {
            name: "",
            items: [
              {
                id: "Others-1",
                name: "Appliances, automatic part, pottery, tile, battery, candle, electric cigarret, cables, cosmetic tool, wooden lighter etc",
              },
            ],
          },
        ],
      },
    ],
  };
}


export function getItemNameById(itemID){
  let dataCard = getDataCard();

  for(var i = 0; i < dataCard.materials.length; i++){
      let material = dataCard.materials[i];
      for(var j = 0; j < material.types.length; j++){
          let type = material.types[j];
          for(var k = 0; k < type.items.length; k++){
              let item = type.items[k];
              if(item.id === itemID) return item.name;
          }
      }
  }

  return undefined;
};

export function gzipCompress(payload){
  let pako = require('pako');
  // json to str
  let strPayload = JSON.stringify(payload,  function(k, v) { return v === undefined ? null : v; });
  // compress (output type Uint8Array)
  let uint8Array = pako.gzip(strPayload);
  
  return uint8Array;
};

export async function fileToBase64(file) {
  return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
          resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
  });
};

export function encryptString(string) {
  // convert the string to a byte-like object
  const secret_key = process.env.REACT_APP_CRYPTO_SECRET_KEY;
  const key = CryptoJS.enc.Utf8.parse(secret_key);
  // encrypt the string using AES algorithm
  const encrypted = CryptoJS.AES.encrypt(string, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  // convert the encrypted data to a string
  return encrypted.toString();
};

export function decryptString(string) {
  // convert the string to a byte-like object
  const secret_key = process.env.REACT_APP_CRYPTO_SECRET_KEY;
  const key = CryptoJS.enc.Utf8.parse(secret_key);
  // decrypt the string using AES algorithm
  const decrypted = CryptoJS.AES.decrypt(string, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  // convert the decrypted data to a string
  return decrypted.toString(CryptoJS.enc.Utf8);
};