import React, { Component } from "react";
import PropTypes from "prop-types";
import { getErrorMessage } from "../Common/Common";
import { Row, Col } from "react-bootstrap";
import "./TransectMonitoringResultData.css";

export default class TransectMonitoringResultData extends Component {
    constructor(props) {
        super(props);
        this.material = this.props.transect.result.materials.find((material) => material.name === this.props.materialDesc.name);
        this.item = this.material.types.find((type) => type.name === this.props.typeDesc.name || type.name === "").items.find((item) => item.id === this.props.itemDesc.id);
    }

    getValue(value, value_cb) {
        if (!isNaN(parseInt(value))) return value_cb();
        return (<span className="text-danger">{getErrorMessage("required")}</span>);
    }

    renderMaterial() {

        return this.props.typeIndex === 0 && this.props.itemIndex === 0 ? (
            <React.Fragment>
                {this.props.materialIndex > 0 ? (
                    <hr className={this.props.classMaterialHr}/>
                ) : undefined}
                <Row className={this.props.classMaterialRow}
                >
                    <Col className={this.props.classMaterial}>{this.material.name}</Col>
                </Row>
                <Row className={this.props.classMaterialWeightRect}>
                    <Col className={this.props.classMaterialWeightName}>Weight(g)</Col>
                    <Col className={this.props.classMaterialWeightValue}>{this.getValue(this.material.weight, ()=>this.material.weight)}</Col>
                </Row>
            </React.Fragment>
        ) : undefined;
    }

    renderMaterialType() {
        return this.props.itemIndex === 0 ? (
            <React.Fragment>
                {this.props.typeCount > 1 ? (
                    <Row>
                        <Col className={this.props.classType}>{this.material.name} - {this.props.typeDesc.name}</Col>
                    </Row>
                ) : undefined}
                <Row className={this.props.classItemHeaderRow}>
                    <Col className={this.props.classItemNameColumn}>Items</Col>
                    <Col sm="auto" className={this.props.classItemValueColumn}>
                        Number
                    </Col>
                </Row>
            </React.Fragment>
        ) : undefined;
    }

    renderItem() {
        return (
            <React.Fragment>
                <Row className={this.props.classItemRow}>
                    <Col className={this.props.classItemName}>{this.props.itemDesc.name}</Col>
                    <Col sm="auto" className={this.props.classItemValue}>
                        {this.getValue(this.item.count, () => this.item.count)}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderMaterial()}
                {this.renderMaterialType()}
                {this.renderItem()}
            </React.Fragment>
        );
    }
}

TransectMonitoringResultData.propTypes = {
    materialIndex: PropTypes.number,
    typeIndex: PropTypes.number,
    itemIndex: PropTypes.number,
    typeCount: PropTypes.number,
    materialDesc: PropTypes.object,
    typeDesc: PropTypes.object,
    itemDesc: PropTypes.object,
    transect: PropTypes.any,

    classMaterialRow: PropTypes.string,
    classMaterial: PropTypes.string,
    classMaterialWeightRect: PropTypes.string,
    classMaterialWeightName: PropTypes.string,
    classMaterialWeightValue: PropTypes.string,
    classType: PropTypes.string,
    classItemHeaderRow: PropTypes.string,
    classItemNameColumn: PropTypes.string,
    classItemValueColumn: PropTypes.string,
    classItemRow: PropTypes.string,
    classItemName: PropTypes.string,
    classItemValue: PropTypes.string,
    classMaterialHr: PropTypes.string,
};
